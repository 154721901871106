import { useMutation } from 'react-query';

import useInsecureClient from 'hooks/useInsecureClient';

/**
 * Возвращает мутацию для выполнения входа в систему.
 */
export default function useLoginMutation() {
  const client = useInsecureClient();

  return useMutation((params: { username: string; password: string }) =>
    client.submitLoginCredentials(params.username, params.password),
  );
}

import { useMutation } from 'react-query';

import useInsecureClient from 'hooks/useInsecureClient';
import ApiHelper from 'helpers/ApiHelper';
import useToasts from 'hooks/useToasts';
import { ToastText } from 'types/Toast';

/**
 * Возвращает мутацию для выполнения подтверждения входа в систему.
 */
export default function useLoginConfirmMutation() {
  const toasts = useToasts();
  const client = useInsecureClient();

  return useMutation(
    (params: { sessionToken: string; login: string; code: string }) =>
      client.submitLoginConfirmationCode(
        params.sessionToken,
        params.login,
        params.code,
      ),
    {
      onError(error) {
        // FIXME: Говнище
        if (!ApiHelper.isError(error, 400, "factor values doesn't match")) {
          toasts.error(ToastText.SessionExpired);
          return;
        }

        toasts.error(ToastText.BadConfirmationCode);
      },
    },
  );
}

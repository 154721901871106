import { useMutation } from 'react-query';

import useInsecureClient from 'hooks/useInsecureClient';

/**
 * Возвращает мутацию для вызова фактора подтверждения входа в систему.
 */
export default function useLoginFactorMutation() {
  const client = useInsecureClient();

  return useMutation((params: { sessionToken: string; login: string }) =>
    client.requestLoginConfirmationCode(params.sessionToken, params.login),
  );
}

import React, { useState } from 'react';

import LoginCredentials from 'components/login/LoginCredentials';
import LoginLayout from 'components/layout/LoginLayout';
import NextPage from 'types/NextPage';
import LoginConfirmation from 'components/login/LoginConfirmation';
import { LoginFactor } from 'types/entities/LoginFactor';
import Tokens from 'types/Tokens';
import useSession from 'hooks/useSession';

/**
 * Отображает страницу входа в систему.
 */
const LoginPage: NextPage = () => {
  const session = useSession();

  const [factor, setFactor] = useState<LoginFactor>();

  function handleLogin(event: LoginFactor | Tokens) {
    if ('factorID' in event) {
      setFactor(event);
    } else {
      session.authorize(event);
    }
  }

  function handleCancelConfirm() {
    setFactor(undefined);
  }

  function handleConfirm(tokens: Tokens) {
    session.authorize(tokens);
  }

  if (factor) {
    return (
      <LoginConfirmation
        factor={factor}
        onCancel={handleCancelConfirm}
        onSuccess={handleConfirm}
      />
    );
  }

  return <LoginCredentials onSuccess={handleLogin} />;
};

LoginPage.Layout = LoginLayout;

export default LoginPage;

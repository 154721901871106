import React, { FC } from 'react';

import { Box, makeStyles } from '@material-ui/core';
import * as Yup from 'yup';

import PasswordInput from 'components/forms/PasswordInput';
import TextInput from 'components/forms/TextInput';
import Button from 'components/ui/Button';
import FieldErrorText from 'constants/FieldErrorText';
import Route from 'constants/Route';
import useForm from 'hooks/useForm';
import useLoginMutation from 'queries/auth/useLoginMutation';
import { LoginFactor } from 'types/entities/LoginFactor';
import ApiHelper from 'helpers/ApiHelper';

import LoginCard from './LoginCard';
import Tokens from 'types/Tokens';

interface Fields {
  /**
   * Логин пользователя.
   */
  username: string;

  /**
   * Пароль пользователя.
   */
  password: string;
}

/**
 * Возвращает стили.
 */
const useStyles = makeStyles({
  input: {
    '&:autofill': {
      background: 'transparent',
      borderRadius: 'inherit',
      transition: 'background-color 0s 50000s',
    },

    '&:-webkit-autofill': {
      background: 'transparent',
      borderRadius: 'inherit',
      transition: 'background-color 0s 50000s',
    },
  },
});

/**
 * Схема данных аутентификации.
 */
const schema = Yup.object().shape({
  username: Yup.string().default('').required(FieldErrorText.EMPTY_USERNAME),
  password: Yup.string().default('').required(FieldErrorText.EMPTY_PASSWORD),
});

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Обработчик события успешного ввода учётных данных.
   * @param factor Данные фактора аутентификации.
   */
  onSuccess?: (factor: LoginFactor | Tokens) => void;
};

/**
 * Отображает карточку ввода имени и пароля пользователя.
 */
const LoginCredentials: FC<Props> = ({ onSuccess }) => {
  const styles = useStyles();

  const login = useLoginMutation();

  const form = useForm<Fields>({
    name: 'loginCredentials',

    validateOnSchemaChange: true,
    validationSchema: schema,

    onSubmit(values) {
      login.mutate(values, {
        onSuccess,
      });
    },
  });

  const loginError = ApiHelper.isError(login.error, 400)
    ? {
        error: true,
        helperText: FieldErrorText.BAD_LOGIN_CREDENTIALS,
      }
    : {};

  return (
    <form {...form.bindForm()}>
      <LoginCard
        title="Вход в личный кабинет"
        subtitle="Авторизуйтесь на внутренней платформе"
        actions={
          <Button href={Route.RECOVERY} color="primary">
            Восстановить пароль
          </Button>
        }
      >
        <Box marginY={2}>
          <TextInput
            {...form.bindTextField('username')}
            {...loginError}
            InputProps={{ classes: styles }}
            label="Имя пользователя"
            variant="filled"
            fullWidth
          />
        </Box>
        <Box marginY={2}>
          <PasswordInput
            {...form.bindTextField('password')}
            InputProps={{ classes: styles }}
            label="Пароль"
            variant="filled"
            fullWidth
          />
        </Box>
        <Box marginY={2}>
          <Button
            {...form.bindSubmitButton()}
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            disabled={login.isLoading}
          >
            Войти
          </Button>
        </Box>
      </LoginCard>
    </form>
  );
};

export default LoginCredentials;

import React, { FC, useEffect } from 'react';

import { Box } from '@material-ui/core';

import Button from 'components/ui/Button';
import PhoneHelper from 'helpers/PhoneHelper';

import LoginCard from './LoginCard';
import LoginConfirmationForm from './LoginConfirmationForm';
import LoginResendButton from './LoginResendButton';
import { LoginFactor } from 'types/entities/LoginFactor';
import useLoginConfirmMutation from 'queries/auth/useLoginConfirmMutation';
import useLoginFactorMutation from 'queries/auth/useLoginFactorMutation';
import Tokens from 'types/Tokens';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Данные фактора аутентификации.
   */
  factor: LoginFactor;

  /**
   * Обработчик события отмены аутентификации.
   */
  onCancel?: () => void;

  /**
   * Обработчик события успешного входа в систему.
   * @param tokens Токены аутентификации.
   */
  onSuccess?: (tokens: Tokens) => void;
};

/**
 * Отображает форму подтверждения мультифакторной аутентификации.
 */
const LoginConfirmation: FC<Props> = ({ factor, onCancel, onSuccess }) => {
  const { mutate: loginFactor, isLoading: loginFactorLoading } =
    useLoginFactorMutation();
  const { mutate: loginConfirm, isLoading: loginConfirmLoading } =
    useLoginConfirmMutation();

  useEffect(() => {
    loginFactor({
      sessionToken: factor.sessionToken,
      login: factor.factorLogin,
    });
  }, [factor, loginFactor]);

  function handleResend() {
    loginFactor({
      sessionToken: factor.sessionToken,
      login: factor.factorLogin,
    });
  }

  function handleSubmit(code: string) {
    loginConfirm(
      {
        sessionToken: factor.sessionToken,
        login: factor.factorLogin,
        code,
      },
      {
        onSuccess,
        onError: onCancel,
      },
    );
  }

  return (
    <>
      <LoginCard
        title="Вход в личный кабинет"
        subtitle={
          <>
            Введите код, отправленный на номер{' '}
            <Box fontWeight="fontWeightBold" display="inline">
              {PhoneHelper.convertSystemToPretty(factor.factorReceiver)}
            </Box>
          </>
        }
        actions={
          <>
            <Box>
              <LoginResendButton
                color="primary"
                disabled={loginConfirmLoading || loginFactorLoading}
                // FIXME: seconds={store.login.countdown.value}
                seconds={0}
                onClick={handleResend}
              />
            </Box>
            <Box>
              <Button color="primary" onClick={onCancel}>
                Назад
              </Button>
            </Box>
          </>
        }
      >
        <LoginConfirmationForm
          onSubmit={handleSubmit}
          disabled={loginConfirmLoading}
          submitText="Войти"
        />
      </LoginCard>
    </>
  );
};

export default LoginConfirmation;
